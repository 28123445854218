// extracted by mini-css-extract-plugin
export var body = "disclosures-module--body--1981b";
export var bodyLarge = "disclosures-module--bodyLarge--e1ae3";
export var bodyLargeLight = "disclosures-module--bodyLargeLight--9160b";
export var bodySmall = "disclosures-module--bodySmall--669a4";
export var bold = "disclosures-module--bold--3da16";
export var corner = "disclosures-module--corner--9df5f";
export var disclosures = "disclosures-module--disclosures--83d68";
export var eyebrow = "disclosures-module--eyebrow--46cf5";
export var filter = "disclosures-module--filter--237bb";
export var homepageHeadline = "disclosures-module--homepageHeadline--20bb6";
export var internalHeadline = "disclosures-module--internalHeadline--93700";
export var italic = "disclosures-module--italic--3a7de";
export var link = "disclosures-module--link--b1ae3";
export var linkSmall = "disclosures-module--linkSmall--0e6f5";
export var numbers = "disclosures-module--numbers--d41f7";
export var quote = "disclosures-module--quote--7d85f";