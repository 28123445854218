import { hasNames } from "../utils";
import Button from "./button";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { forwardRef, useState } from "react";



import ChevronLeft from "../images/chevron-left.icon.svg";
import ChevronRight from "../images/chevron-right.icon.svg";



import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/quote-carousel.module.scss";


const QuoteCarousel = forwardRef((props, ref) => {
	const { label = null, varient = "none", quotes = [] } = props;
	const [currSlide, setCurrSlide] = useState(0);

	function prevQuote() {
		let n = currSlide - 1;
		if (n < 0) {
			n = quotes.length - 1;
		}
		setCurrSlide(n);
	}
	function nextQuote() {
		let n = currSlide + 1;
		if (n > quotes.length - 1) {
			n = 0;
		}
		setCurrSlide(n);
	}

	return (
		<div
			ref={ref}
			className={hasNames(styles.quoteCarousel, styles["varient_" + varient])}>
			<div>
				{label !== null ? <div className={styles.label}>{label}</div> : null}
				<figure
					className={hasNames(grd.gridContainer, styles.quoteCarouselContainer)}>
					{quotes.map((quote, n) => {
						if (quote.image === undefined || quote.image === null) {
							quote.image = (
								<StaticImage
									src={`../images/people/_dark-missing.png`}
									alt={quote.person}
									placeholder="blurred"
									width={70}
									height={70}
									layout="fixed"
								/>
							);
						}
						return (
							<div
								className={hasNames(
									styles.quoteWrap,
									n === currSlide ? "active" : ""
								)}
								key={`quote-carousel-slide-${n}`}>
								<figcaption>
									<div className={styles.image}>{quote.image}</div>
									<div className={styles.person}>
										{quote.person}
										<span className={styles.position}>{quote.position}</span>
									</div>
								</figcaption>
								<blockquote>
									<div className={styles.big}>{quote.bigText}</div>
									<div className={styles.small}>{quote.smallText}</div>
								</blockquote>
							</div>
						);
					})}
				</figure>
				{quotes.length <= 1 ? null : (
					<div className={styles.moverShaker}>
						<Button
							compact="true"
							onClick={prevQuote}
							type="btnAltSecondary"
							className={styles.arrows}
							ariaLabel="Previous">
							<ChevronLeft className={styles.chevron} />
						</Button>
						<div className={styles.dots}>
							{quotes.map((q, n) => {
								return (
									<button
										className={hasNames(
											styles.dot,
											n === currSlide ? "active" : ""
										)}
										onClick={() => {
											setCurrSlide(n);
										}}
										key={`quote-carousel-dot-${n}`}>
										&bull;
									</button>
								);
							})}
						</div>
						<Button
							compact="true"
							onClick={nextQuote}
							type="btnAltSecondary"
							className={styles.arrows}
							ariaLabel="Next">
							<ChevronRight className={styles.chevron} />
						</Button>
					</div>
				)}
			</div>
		</div>
	);
});

export default QuoteCarousel;
