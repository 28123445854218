import { hasNames } from "../utils";
import Button from "./button";
import Disclosures from "./disclosures";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { forwardRef } from "react";

import ChevronLeft from "../images/chevron-left.icon.svg";
import ChevronRight from "../images/chevron-right.icon.svg";

import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/our-history.module.scss";
import * as typ from "../styles/typography.module.scss";

const OurHistory = forwardRef((props, ref) => {
	const [currentSlide, setCurrentSlide] = React.useState(1);

	function prevSlide() {
		if (currentSlide <= 1) {
			setCurrentSlide(3);
		} else {
			setCurrentSlide(currentSlide - 1);
		}
	}

	function nextSlide() {
		if (currentSlide >= 3) {
			setCurrentSlide(1);
		} else {
			setCurrentSlide(currentSlide + 1);
		}
	}

	function setSlide(n) {
		setCurrentSlide(n);
	}

	return (
		<div ref={ref} className={hasNames(styles.ourHistory, grd.container)}>
			<div className={styles.container}>
				<div
					className={hasNames(
						styles.slide,
						currentSlide === 1 ? "active" : ""
					)}>
					<div className={styles.header}>
						<div className={hasNames(typ.eyebrow)}>Our History</div>
						<h2 className={styles.header}>
							Building on over <em>150</em> years of tradition.
						</h2>
					</div>
					<div className={styles.content}>
						At the root of our approach is David Eccles’ investment philosophy. After coming to America in 1863 aboard the ship Cynosure, David became one of the most successful entrepreneurs and investors in U.S. history over the next several decades.
						<Disclosures>
							<>Arrington, L. J. (1975). David Eccles: Pioneer Western Industrialist. Logan, Utah: Utah State University.</>
							<>Flake, C.J. (1909). Sketches of the Inter-Mountain States 1847 1909 Utah Idaho Nevada. Salt Lake City, Utah: Salt Lake Tribune.</>
							<>
								Arrington, L. J., (1975). David Eccles. In Utah History Encyclopedia. Retrieved from uen.org.
							</>
						</Disclosures>
						<div className={styles.controls}>
							<Button
								compact="true"
								onClick={prevSlide}
								type="btnAltSecondary"
								className={styles.arrows}
								ariaLabel="Previous">
								<ChevronLeft className={styles.chevron} />
							</Button>
							<div className={styles.dots}>
								<button
									className={hasNames(styles.dot, "active")}
									onClick={() => {
										setSlide(1);
									}}>
									&bull;
								</button>
								<button
									className={hasNames(styles.dot)}
									onClick={() => {
										setSlide(2);
									}}>
									&bull;
								</button>
								<button
									className={hasNames(styles.dot)}
									onClick={() => {
										setSlide(3);
									}}>
									&bull;
								</button>
							</div>
							<Button
								compact="true"
								onClick={nextSlide}
								type="btnAltSecondary"
								className={styles.arrows}
								ariaLabel="Next">
								<ChevronRight className={styles.chevron} />
							</Button>
						</div>
					</div>
					<div className={styles.image}>
						<StaticImage
							src="../images/history-1-salt-lake-city.jpg"
							alt="Salt Lake City"
							aspectRatio={1 / 1}
							layout="constrained"
						/>
						<div className={styles.imageDesc}>
							Artist rendering of historic Salt Lake City.
						</div>
					</div>
				</div>

				<div
					className={hasNames(
						styles.slide,
						currentSlide === 2 ? "active" : ""
					)}>
					<div className={styles.header}>
						<div className={hasNames(typ.eyebrow)}>Our History</div>
						<h2 className={styles.header}>
							An enduring philosophy of partnership.
						</h2>
					</div>
					<div className={styles.content}>
						David  Eccles’ investment approach was to partner with founders and management-owners. His sons, George and Marriner, continued this philosophy resulting in some of the biggest landmark transactions of the era.
						<div className={styles.controls}>
							<Button
								compact="true"
								onClick={prevSlide}
								type="btnAltSecondary"
								className={styles.arrows}
								ariaLabel="Previous">
								<ChevronLeft className={styles.chevron} />
							</Button>
							<div className={styles.dots}>
								<button
									className={hasNames(styles.dot)}
									onClick={() => {
										setSlide(1);
									}}>
									&bull;
								</button>
								<button
									className={hasNames(styles.dot, "active")}
									onClick={() => {
										setSlide(2);
									}}>
									&bull;
								</button>
								<button
									className={hasNames(styles.dot)}
									onClick={() => {
										setSlide(3);
									}}>
									&bull;
								</button>
							</div>
							<Button
								compact="true"
								onClick={nextSlide}
								type="btnAltSecondary"
								className={styles.arrows}
								ariaLabel="Next">
								<ChevronRight className={styles.chevron} />
							</Button>
						</div>
					</div>
					<div className={styles.image}>
						<StaticImage
							src="../images/history-2-first-security.jpg"
							transformOptions={{ cropFocus: `north` }}
							alt="First Security Building"
							aspectRatio={1 / 1}
							layout="constrained"
						/>
						<div className={styles.imageDesc}>
							Our first offices, located at the iconic First Security building.
						</div>
					</div>
				</div>

				<div
					className={hasNames(
						styles.slide,
						currentSlide === 3 ? "active" : ""
					)}>
					<div className={styles.header}>
						<div className={hasNames(typ.eyebrow)}>Our History</div>
						<h2 className={styles.header}>Continuing the legacy today.</h2>
					</div>
					<div className={styles.content}>
						Today, we carry on the Eccles tradition of collaborative investing—not only on behalf of our foundational clients, but of many other like-minded individuals, institutions, and multi-generational families as well.
						<div className={styles.controls}>
							<Button
								compact="true"
								onClick={prevSlide}
								type="btnAltSecondary"
								className={styles.arrows}
								ariaLabel="Previous">
								<ChevronLeft className={styles.chevron} />
							</Button>
							<div className={styles.dots}>
								<button
									className={hasNames(styles.dot)}
									onClick={() => {
										setSlide(1);
									}}>
									&bull;
								</button>
								<button
									className={hasNames(styles.dot)}
									onClick={() => {
										setSlide(2);
									}}>
									&bull;
								</button>
								<button
									className={hasNames(styles.dot, "active")}
									onClick={() => {
										setSlide(3);
									}}>
									&bull;
								</button>
							</div>
							<Button
								compact="true"
								onClick={nextSlide}
								type="btnAltSecondary"
								className={styles.arrows}
								ariaLabel="Next">
								<ChevronRight className={styles.chevron} />
							</Button>
						</div>
					</div>
					<div className={styles.image}>
						<StaticImage
							src="../images/history-3-salt-lake-city-today.jpg"
							transformOptions={{ cropFocus: `centre` }}
							alt="First Security Building"
							aspectRatio={1 / 1}
							layout="constrained"
						/>
						<div className={styles.imageDesc}>Modern-day Salt Lake City.</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default OurHistory;
