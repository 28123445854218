import { hasNames } from "../utils";
import Button from "./button";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { forwardRef } from "react";

import ExternalLinkIcon from "../images/external-link.icon.svg";

import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/our-locations.module.scss";

const OurLocations = forwardRef((props, ref) => {
	const data = useStaticQuery(graphql`
		query MyQuery {
			allFile(
				filter: {
					absolutePath: {
						regex: "/src/images/(new-york-city|salt-lake-city).jpg/i"
					}
				}
			) {
				nodes {
					childrenImageSharp {
						gatsbyImageData(width: 700, layout: FULL_WIDTH)
					}
				}
			}
		}
	`);

	const imgNYC = getImage(data.allFile.nodes[0].childrenImageSharp[0]);
	const imgSLC = getImage(data.allFile.nodes[1].childrenImageSharp[0]);

	return (
		<div ref={ref} className={styles.ourLocations}>
			<div className={hasNames(com.monobrow, styles.brow)}>Our Locations</div>
			<h2 className={hasNames(grd.container, styles.sectionHeader)}>
				We are based in <em>Salt Lake City</em> and <em>New York</em>, and will work with you wherever your business takes you.
			</h2>
			<div className={grd.gridContainer}>
				<div className={hasNames(grd.col6, grd.colMd12)}>
					<GatsbyImage
						image={imgSLC}
						alt="Salt Lake City"
						className={com.imageClipped}
					/>
					<div className={styles.locationLink}>
						Salt Lake City
						<Button type="tertiarySolid" className={styles.locationButton} to="https://www.google.com/maps/place/111+S+Main+St+%232350,+Salt+Lake+City,+UT+84111/@40.7668636,-111.8927545,17z/" target="_blank">
							See Location <ExternalLinkIcon />
						</Button>
					</div>
				</div>
				<div className={hasNames(grd.col6, grd.colMd12)}>
					<GatsbyImage
						image={imgNYC}
						alt="New York City"
						className={com.imageClipped}
					/>
					<div className={styles.locationLink}>
						New York City
						<Button type="tertiarySolid" className={styles.locationButton} to="https://www.google.com/maps/place/152+W+57th+St+%2316th,+New+York,+NY+10019/@40.7649838,-73.9817037,17z/" target="_blank">
							See Location <ExternalLinkIcon />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
});

export default OurLocations;
