import { hasNames } from "../utils";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Lottie from "lottie-react";
import { useEffect, useRef } from "react";
import * as React from "react";



import Button from "../components/button";
import ContactUsFooter from "../components/contact-us-footer";
import FiftyFifty from "../components/fifty-fifty";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import Hero from "../components/hero";
import InteractiveScroll from "../components/interactive-scroll";
import NewsTeaserCard from "../components/news-teaser-card";
import OurHistory from "../components/our-history";
import OurLocations from "../components/our-locations";
import PartnershipsFeature from "../components/partnerships-feature";
import QuoteCarousel from "../components/quote-carousel";
import RevisingText from "../components/revising-text";



import LottieAlternative from "../images/our-approach-mobile.inline.svg";
import lottieRings from "../images/our-approach-rings.json";

import * as com from "../styles/common.module.scss";
import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/index.module.scss";
import * as typ from "../styles/typography.module.scss";

export const Head = () => {
	return <HeadMeta title="Collaborative Equity Investors" />;
};

const IndexPage = ({ data }) => {
	const heroRef = useRef(null);
	const approachRef = useRef(null);
	const partnershipRef = useRef(null);
	const quoteRef = useRef(null);
	const locationsRef = useRef(null);
	const historyRef = useRef(null);
	const newsRef = useRef(null);
	const contactRef = useRef(null);

	const scrollLocations = [
		{ label: "What We Do", ref: heroRef },
		{ label: "Our Approach", ref: approachRef },
		{ label: "Partnerships Features", ref: partnershipRef },
		{ label: "What We Say", ref: quoteRef },
		{ label: "Our Locations", ref: locationsRef },
		{ label: "Our History", ref: historyRef },
		{ label: "News", ref: newsRef },
		{ label: "Footer", ref: contactRef },
	];

	const lottieRef = useRef(null);
	function watchScrollForAnimation() {
		const el = approachRef.current;

		const target = el !== null ? el.getBoundingClientRect().height / 2 : -1;

		if (el !== null && window.scrollY > target) {
			lottieRef.current.play();
		}
	}
	useEffect(() => {
		const scrollWatcher = window.addEventListener(
			"scroll",
			watchScrollForAnimation,
			{ passive: true }
		);
		return () => {
			window.removeEventListener("scroll", scrollWatcher);
		};
	}, [approachRef.current]);

	return (
		<Frame>
			<InteractiveScroll locations={scrollLocations} />
			<Hero ref={heroRef} image="Meeting">
				<RevisingText
					revisions={["Founders", "Management", "Employees", "Owners"]}>
					Investing <em>with</em>{" "}
				</RevisingText>
			</Hero>

			<div className={styles.backCover}>
				<FiftyFifty useRef={approachRef} className={styles.lottieAnimation}>
					<div className={styles.leftHalf}>
						<Lottie
							className={styles.desktop}
							animationData={lottieRings}
							loop={false}
							autoplay={false}
							lottieRef={lottieRef}
						/>
						<LottieAlternative className={styles.mobile} />
					</div>
					<div className="extra-gap">
						<div className={typ.eyebrow}>Our Approach</div>
						<h2 className={com.browedHeader}>
							Helping good people do great things.
						</h2>
						<div className={typ.bodyLargeLight}>
							Your company. Your vision. And with flexible capital, we’ll help
							you to succeed. The best companies are built from strong
							partnerships—that’s our foundational belief.
						</div>
					</div>
				</FiftyFifty>
			</div>
			<PartnershipsFeature ref={partnershipRef} />
			<div className={styles.backCover}>
				<QuoteCarousel
					ref={quoteRef}
					label="What Founders Say"
					quotes={[
						{
							image: (
								<StaticImage
									src={`../images/people/testimonial-plancorp-chris-kerckhoff.png`}
									alt="Chris Kerckhoff"
									placeholder="blurred"
									width={75}
									height={75}
									layout="fixed"
								/>
							),
							person: `Chris Kerckhoff`,
							position: `CEO of Plancorp`,
							bigText: `“ We have a fantastic working relationship with Cynosure. Cynosure holds two board seats on our board and always acts thoughtfully and professionally. The relationship truly feels like a partnership. ”`,
						},
						{
							image: (
								<StaticImage
									src={`../images/people/testimonial-dirty-dogs-martin-emmett.png`}
									alt="Martin Emmett"
									placeholder="blurred"
									width={75}
									height={75}
									layout="fixed"
								/>
							),
							person: `Martin Emmett`,
							position: `President & CEO of Dirty Dogs`,
							bigText: `“ I’m happy about how collaborative every conversation is. ”`,
						},
						{
							image: (
								<StaticImage
									src={`../images/people/testimonial-fresh-consulting-jeff-dance.png`}
									alt="Jeff Dance"
									placeholder="blurred"
									width={75}
									height={75}
									layout="fixed"
								/>
							),
							person: `Jeff Dance`,
							position: `Founder & CEO of Fresh Consulting`,
							bigText: `“ Cynosure is authentic, smart, supportive, and strategic. ”`,
						},
						{
							image: (
								<StaticImage
									src={`../images/people/testimonial-visible-supply-chain-casey-adams.png`}
									alt="Casey Adams"
									placeholder="blurred"
									width={75}
									height={75}
									layout="fixed"
								/>
							),
							person: `Casey Adams`,
							position: `President of Visible Supply Chain`,
							bigText: `“ With Cynosure, trust and respect are very high. ”`,
						},
						{
							image: (
								<StaticImage
									src={`../images/people/testimonial-sg-credit-partners-marc-cole.png`}
									alt="Marc Cole"
									placeholder="blurred"
									width={75}
									height={75}
									layout="fixed"
								/>
							),
							person: `Marc Cole`,
							position: `CEO of SG Credit Partners`,
							bigText: `“ Cynosure took the time to understand our vision and needs prior to their investment, but, more importantly, they’ve continued to do so post-investment. ”`,
						},
					]}
				/>
				<OurLocations ref={locationsRef} />
				<OurHistory ref={historyRef} />
				<div className={com.monobrow}>Our News</div>
				<h2 className={com.centerlineHeader}>What’s Currently Happening</h2>
				<div className={grd.gridContainer} ref={newsRef}>
					{data.newsArticles.nodes.map((news) => {
						return (
							<NewsTeaserCard
								key={"home-page-news-preview-" + news.id}
								className={hasNames(grd.col4, grd.colSm12)}
								tag={news.frontmatter.tags[0]}
								title={news.frontmatter.title}
								date={news.frontmatter.date}
								copy={news.excerpt}
								link={news.fields.url}
							/>
						);
					})}
				</div>
				<Button to="/news" type="secondary" className={com.centerlineButter}>
					See All Articles
				</Button>
				<ContactUsFooter ref={contactRef} />
			</div>
		</Frame>
	);
};

export default IndexPage;

export const query = graphql`
	query HomePageQuery {
		newsArticles: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/src/cms/data/news/" } }
			sort: { fields: frontmatter___date, order: DESC }
			limit: 3
		) {
			nodes {
				id
				frontmatter {
					title
					date(formatString: "MMMM Do YYYY")
					tags
				}
				fields {
					url
				}
				excerpt(pruneLength: 128)
			}
		}
	}
`;
