import { getViewportSize, hasNames } from "../utils";
import Button from "./button";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { forwardRef, useEffect, useState } from "react";



// import LogoFin from "../images/logomark-fin.inline.svg";
import LogoLeft from "../images/logomark-left-large.inline.svg";
import LogoRight from "../images/logomark-right-large.inline.svg";



import * as grd from "../styles/grid.module.scss";
import * as styles from "../styles/partnership-feature.module.scss";


const INITIAL_START_DELAY = 200;

const PartnershipsFeature = forwardRef((props, ref) => {
	const [continueAnimating, setContinueAnimating] = useState(false);

	const [leftTop, setLeftTop] = useState("0px");
	const [leftRight, setLeftRight] = useState("0px");

	const [rightTop, setRightTop] = useState("0px");
	const [rightLeft, setRightLeft] = useState("0px");

	const [backgroundPositionVert, setBackgroundPositionVert] = useState("190px");

	useEffect(() => {
		function onAnimationFrame() {
			const elPos = document
				.querySelector(
					"." + styles.partnershipFancy + " ." + styles.contentWrap
				)
				?.getBoundingClientRect();
			if (elPos === undefined) {
				return;
			}

			// Manage background positioning
			if (getViewportSize() !== "Xs" && getViewportSize() !== "Sm") {
				setBackgroundPositionVert("0px");
			} else {
				if (elPos.top < INITIAL_START_DELAY * -1) {
					setBackgroundPositionVert(
						Math.min(
							190,
							Math.max(0, 190 - (Math.abs(elPos.top * 1) - INITIAL_START_DELAY))
						) + "px"
					);
				} else {
					setBackgroundPositionVert("190px");
				}
			}

			// Manage the top positioning
			let newLeftTop = "0px";
			if (elPos.top < 0) {
				newLeftTop = Math.abs(elPos.top) + "px";
				if (elPos.top < INITIAL_START_DELAY * -1) {
					newLeftTop =
						Math.abs(elPos.top) + elPos.top + INITIAL_START_DELAY + "px";
				}
			}
			setLeftTop(newLeftTop);

			let newRightTop = "0px";
			if (elPos.top < 0) {
				newRightTop = Math.abs(elPos.top) + "px";
				if (elPos.top < INITIAL_START_DELAY * -1) {
					newRightTop =
						Math.abs(elPos.top) +
						Math.abs(elPos.top) -
						INITIAL_START_DELAY +
						"px";
				}
			}
			setRightTop(newRightTop);

			// Manage the horizontal positioning
			const leftSideRightCenteringPos = elPos.width / 2 + elPos.left;
			if (elPos.top > INITIAL_START_DELAY * -1) {
				setLeftRight(leftSideRightCenteringPos - 300 + "px");
			} else {
				setLeftRight(
					leftSideRightCenteringPos -
						300 +
						Math.abs(elPos.top + INITIAL_START_DELAY) +
						"px"
				);
			}
			const rightSideLeftCenteringPos = elPos.width / 2; // + elPos.right;
			if (elPos.top > INITIAL_START_DELAY * -1) {
				setRightLeft(rightSideLeftCenteringPos - 300 + "px");
			} else {
				setRightLeft(
					rightSideLeftCenteringPos -
						300 +
						Math.abs(elPos.top + INITIAL_START_DELAY) +
						"px"
				);
			}

			// Again!
			if (continueAnimating) {
				window.requestAnimationFrame(onAnimationFrame);
			}
		}
		// Kickoff
		setContinueAnimating(true);
		window.requestAnimationFrame(onAnimationFrame);
		// window.addEventListener("scroll", onAnimationFrame, { passive: true });
		return () => {
			setContinueAnimating(false);
		};
	}, [continueAnimating]);

	return (
		<div
			ref={ref}
			className={styles.partnershipFancy}
			style={{
				backgroundPositionY: backgroundPositionVert,
			}}>

			<StaticImage src="../images/summit-xlarge.jpg" className={styles.summitBgLarge} layout="fullWidth" aria-hidden="true" alt="" quality={85} />
			<StaticImage src="../images/summit-medium.jpg" className={styles.summitBgMedium} layout="fullWidth" aria-hidden="true" alt="" quality={85} />
			<StaticImage src="../images/summit-small.jpg" className={styles.summitBgSmall} layout="fullWidth" aria-hidden="true" alt="" quality={85} />

			<div className={hasNames(grd.container, styles.contentWrap)}>
				<div className={styles.topContent}>
					<div className={styles.brow}>Our Partnerships</div>
					<h2>Collaborative solutions aligned with your vision.</h2>
					<p>
						We’re proud of our track record, listening to our partners, sharing ideas, and working side-by-side to help them succeed.
					</p>
					<Button to="/partnerships/founders/">Meet Our Partners</Button>
				</div>
				<div className={styles.bottomContent}>
					<div className={styles.brow}>A Founder Story</div>{" "}
					<h2>Fresh Consulting</h2>
					<StaticImage src="../images/fresh-consulting-home.jpg" alt="Fresh Consulting's Jeff Dance"/>
					<p>
						Fresh Consulting is a homemade company—literally—as founder and CEO
						Jeff Dance started it in his mother’s basement. His goal was to
						create a consulting firm that capitalized upon shifting paradigms of
						workplace collaboration and connectivity to align new technologies
						with people. Over time, this has allowed Fresh to be at the
						forefront of technology shifts through their award-winning work
						with other 400 clients, from startups to Fortune 100 companies
					</p>
					<Button to={"/partnerships/founders/"} className={styles.foundersButton}>Read More</Button>
				</div>
			</div>
			{/* <StaticImage
				src={`../images/summit.jpg`}
				alt=""
				aria-hidden="true"
				placeholder="blurred"
				layout="constrained"
				className={styles.backdrop}
			/> */}
			<LogoLeft
				aria-hidden={true}
				alt=""
				className={hasNames(styles.logoHalves, styles.logoLeftHalf)}
				style={{
					top: leftTop,
					right: leftRight,
				}}
			/>
			<LogoRight
				aria-hidden={true}
				alt=""
				className={hasNames(styles.logoHalves, styles.logoRightHalf)}
				style={{
					top: rightTop,
					left: rightLeft,
				}}
			/>
			{/* <LogoFin
				aria-hidden={true}
				alt=""
				className={hasNames(styles.logoHalves, styles.logoFin)}
			/> */}
		</div>
	);
});

export default PartnershipsFeature;
