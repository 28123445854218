import { hasNames } from "../utils";
import * as React from "react";
import { useEffect, useState } from "react";



import * as styles from "../styles/revising-text.module.scss";


const RevisingText = ({ revisions = ["No revisions set."], children }) => {
	const [animState, setAnimState] = useState("write_complete");
	const [revisionData, setRevisionData] = useState(
		revisions.map((revision, revisionIndex) => {
			return convertStringToAnimationData(revision, revisionIndex === 0);
		})
	);
	const [currentRevision, setCurrentRevision] = useState(0);
	const [currentCharacter, setCurrentCharacter] = useState(
		revisionData[currentRevision].length
	);

	function convertStringToAnimationData(string, visible = false) {
		return string.split("").map((letter) => {
			return {
				char: letter,
				visible: visible,
			};
		});
	}

	useEffect(() => {
		// Animation Actions
		function deleteText() {
			let workingData = revisionData;
			if (workingData[currentRevision][currentCharacter] !== undefined) {
				workingData[currentRevision][currentCharacter].visible = false;
				setCurrentCharacter(currentCharacter - 1);
			} else {
				setAnimState("delete_complete");
			}
			setRevisionData(workingData);
		}
		function swapText() {
			let newStatement = currentRevision + 1;
			if (newStatement > revisionData.length - 1) {
				newStatement = 0;
			}
			setCurrentRevision(newStatement);
			setCurrentCharacter(0);
			setAnimState("write");
		}
		function writeText() {
			let workingData = revisionData;
			if (workingData[currentRevision][currentCharacter] !== undefined) {
				workingData[currentRevision][currentCharacter].visible = true;
				setCurrentCharacter(currentCharacter + 1);
			} else {
				setAnimState("write_complete");
			}
			setRevisionData(workingData);
		}
		function holdText() {
			setCurrentCharacter(currentCharacter - 1);
			setAnimState("delete");
		}

		// State management
		switch (animState) {
			case "delete":
				setTimeout(deleteText, 33);
				break;
			case "delete_complete":
				setTimeout(swapText, 99);
				break;
			case "write":
				setTimeout(writeText, 66);
				break;
			case "write_complete":
				setTimeout(
					holdText,
					currentRevision === revisionData.length - 1 ? 3200 : 1550
				);
				break;
			default:
				break;
		}
		return () => {
			clearTimeout(deleteText);
			clearTimeout(swapText);
			clearTimeout(writeText);
			clearTimeout(holdText);
		};
	}, [currentCharacter, animState, currentRevision, revisionData]);

	return (
		<div className={styles.revisingText}>
			{revisionData.map((revision, revisionIndex) => {
				let cursorDisplayed = false;
				return (
					<div
						key={`revision-${revisionIndex}`}
						className={hasNames(
							styles.statement,
							revisionIndex === currentRevision ? "active" : ""
						)}>
						{children}
						<div>
							{revision.map((charData, charIndex) => {
								const char = (
									<span
										key={`revision-${revisionIndex}-${charIndex}`}
										className={
											charData.visible ? styles.visible : styles.hidden
										}>
										{charData.char}
									</span>
								);
								let out = [
									<span
										key={`revision-${revisionIndex}-cursor`}
										className={styles.cursor}>
										|
									</span>,
								];
								if (!charData.visible && !cursorDisplayed) {
									out.push(char);
									cursorDisplayed = true;
								} else {
									out[0] = char;
								}
								return out;
							})}
							{cursorDisplayed ? null : (
								<span className={styles.cursor}>|</span>
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default RevisingText;
