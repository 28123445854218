// extracted by mini-css-extract-plugin
export var body = "revising-text-module--body--5dbfb";
export var bodyLarge = "revising-text-module--bodyLarge--a1daa";
export var bodyLargeLight = "revising-text-module--bodyLargeLight--9d412";
export var bodySmall = "revising-text-module--bodySmall--602a1";
export var bold = "revising-text-module--bold--a7417";
export var cursor = "revising-text-module--cursor--7b3c5";
export var eyebrow = "revising-text-module--eyebrow--0c72e";
export var filter = "revising-text-module--filter--d6315";
export var hidden = "revising-text-module--hidden--bf0eb";
export var homepageHeadline = "revising-text-module--homepageHeadline--238b0";
export var internalHeadline = "revising-text-module--internalHeadline--5e186";
export var italic = "revising-text-module--italic--763c4";
export var link = "revising-text-module--link--40895";
export var linkSmall = "revising-text-module--linkSmall--54da9";
export var quote = "revising-text-module--quote--cdd5b";
export var revisingText = "revising-text-module--revisingText--b3759";
export var statement = "revising-text-module--statement--5f49c";
export var visible = "revising-text-module--visible--9cae8";