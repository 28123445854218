// extracted by mini-css-extract-plugin
export var backdrop = "partnership-feature-module--backdrop--7159a";
export var body = "partnership-feature-module--body--13b37";
export var bodyLarge = "partnership-feature-module--bodyLarge--53349";
export var bodyLargeLight = "partnership-feature-module--bodyLargeLight--2fe01";
export var bodySmall = "partnership-feature-module--bodySmall--d8e3d";
export var bold = "partnership-feature-module--bold--b955a";
export var bottomContent = "partnership-feature-module--bottomContent--684c2";
export var brow = "partnership-feature-module--brow--05477";
export var contentWrap = "partnership-feature-module--contentWrap--e9057";
export var eyebrow = "partnership-feature-module--eyebrow--24159";
export var filter = "partnership-feature-module--filter--e3993";
export var foundersButton = "partnership-feature-module--foundersButton--3b02b";
export var homepageHeadline = "partnership-feature-module--homepageHeadline--1c2c0";
export var internalHeadline = "partnership-feature-module--internalHeadline--617ec";
export var italic = "partnership-feature-module--italic--2bae2";
export var link = "partnership-feature-module--link--17d72";
export var linkSmall = "partnership-feature-module--linkSmall--16837";
export var logoHalves = "partnership-feature-module--logoHalves--7b0a0";
export var logoLeftHalf = "partnership-feature-module--logoLeftHalf--89dee";
export var logoRightHalf = "partnership-feature-module--logoRightHalf--e0118";
export var partnershipFancy = "partnership-feature-module--partnershipFancy--3796c";
export var quote = "partnership-feature-module--quote--42d70";
export var summitBgLarge = "partnership-feature-module--summitBgLarge--4c42a";
export var summitBgMedium = "partnership-feature-module--summitBgMedium--836fe";
export var summitBgSmall = "partnership-feature-module--summitBgSmall--72598";
export var topContent = "partnership-feature-module--topContent--2a875";