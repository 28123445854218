// extracted by mini-css-extract-plugin
export var arrows = "quote-carousel-module--arrows--e1cea";
export var big = "quote-carousel-module--big--5e644";
export var body = "quote-carousel-module--body--ee029";
export var bodyLarge = "quote-carousel-module--bodyLarge--a78ec";
export var bodyLargeLight = "quote-carousel-module--bodyLargeLight--a552b";
export var bodySmall = "quote-carousel-module--bodySmall--2a40e";
export var bold = "quote-carousel-module--bold--220ff";
export var chevron = "quote-carousel-module--chevron--ff36d";
export var col0 = "quote-carousel-module--col0--d23dd";
export var col1 = "quote-carousel-module--col1--769c8";
export var col10 = "quote-carousel-module--col10--16339";
export var col11 = "quote-carousel-module--col11--0f2e2";
export var col12 = "quote-carousel-module--col12--a2335";
export var col2 = "quote-carousel-module--col2--8b34c";
export var col3 = "quote-carousel-module--col3--f07bf";
export var col4 = "quote-carousel-module--col4--19e5a";
export var col5 = "quote-carousel-module--col5--a8f45";
export var col6 = "quote-carousel-module--col6--6b678";
export var col7 = "quote-carousel-module--col7--84520";
export var col8 = "quote-carousel-module--col8--1e1f5";
export var col9 = "quote-carousel-module--col9--b1a4e";
export var colLg0 = "quote-carousel-module--colLg0--5fa36";
export var colLg1 = "quote-carousel-module--colLg1--72914";
export var colLg10 = "quote-carousel-module--colLg10--c417c";
export var colLg11 = "quote-carousel-module--colLg11--4dfc9";
export var colLg12 = "quote-carousel-module--colLg12--feb28";
export var colLg2 = "quote-carousel-module--colLg2--75b53";
export var colLg3 = "quote-carousel-module--colLg3--7205d";
export var colLg4 = "quote-carousel-module--colLg4--0e7b7";
export var colLg5 = "quote-carousel-module--colLg5--8afff";
export var colLg6 = "quote-carousel-module--colLg6--ce4ff";
export var colLg7 = "quote-carousel-module--colLg7--8a840";
export var colLg8 = "quote-carousel-module--colLg8--067dd";
export var colLg9 = "quote-carousel-module--colLg9--68ed3";
export var colMd0 = "quote-carousel-module--colMd0--e44c0";
export var colMd1 = "quote-carousel-module--colMd1--28291";
export var colMd10 = "quote-carousel-module--colMd10--73f2a";
export var colMd11 = "quote-carousel-module--colMd11--c3472";
export var colMd12 = "quote-carousel-module--colMd12--f950c";
export var colMd2 = "quote-carousel-module--colMd2--bc14c";
export var colMd3 = "quote-carousel-module--colMd3--03f68";
export var colMd4 = "quote-carousel-module--colMd4--a120b";
export var colMd5 = "quote-carousel-module--colMd5--1acd8";
export var colMd6 = "quote-carousel-module--colMd6--a2a5f";
export var colMd7 = "quote-carousel-module--colMd7--ba052";
export var colMd8 = "quote-carousel-module--colMd8--99303";
export var colMd9 = "quote-carousel-module--colMd9--27a64";
export var colSm0 = "quote-carousel-module--colSm0--d599b";
export var colSm1 = "quote-carousel-module--colSm1--ad6a4";
export var colSm10 = "quote-carousel-module--colSm10--6b6d5";
export var colSm11 = "quote-carousel-module--colSm11--34145";
export var colSm12 = "quote-carousel-module--colSm12--b29b4";
export var colSm2 = "quote-carousel-module--colSm2--98476";
export var colSm3 = "quote-carousel-module--colSm3--552d2";
export var colSm4 = "quote-carousel-module--colSm4--29659";
export var colSm5 = "quote-carousel-module--colSm5--6460a";
export var colSm6 = "quote-carousel-module--colSm6--9ddc5";
export var colSm7 = "quote-carousel-module--colSm7--e455a";
export var colSm8 = "quote-carousel-module--colSm8--d438c";
export var colSm9 = "quote-carousel-module--colSm9--1c0fb";
export var container = "quote-carousel-module--container--e6d3d";
export var dot = "quote-carousel-module--dot--d025a";
export var dots = "quote-carousel-module--dots--6208a";
export var eyebrow = "quote-carousel-module--eyebrow--2b216";
export var filter = "quote-carousel-module--filter--13f6b";
export var gridContainer = "quote-carousel-module--gridContainer--95aae";
export var homepageHeadline = "quote-carousel-module--homepageHeadline--daca5";
export var image = "quote-carousel-module--image--53677";
export var internalHeadline = "quote-carousel-module--internalHeadline--2830c";
export var italic = "quote-carousel-module--italic--32cdf";
export var label = "quote-carousel-module--label--96585";
export var link = "quote-carousel-module--link--8badf";
export var linkSmall = "quote-carousel-module--linkSmall--51e72";
export var moverShaker = "quote-carousel-module--moverShaker--fd3f2";
export var person = "quote-carousel-module--person--fe107";
export var position = "quote-carousel-module--position--22236";
export var quote = "quote-carousel-module--quote--ba146";
export var quoteCarousel = "quote-carousel-module--quoteCarousel--07c85";
export var quoteCarouselContainer = "quote-carousel-module--quoteCarouselContainer--b7038";
export var quoteWrap = "quote-carousel-module--quoteWrap--2534b";
export var small = "quote-carousel-module--small--40ee4";
export var varient_white = "quote-carousel-module--varient_white--bc9a2";