import { hasNames } from "../utils";
import * as React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";



import * as styles from "../styles/disclosures.module.scss";


const Disclosures = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [leftOffset, setLeftOffset] = useState(0);
	const [topOffset, setTopOffset] = useState(0);
	const [carrotSide, setCarrotSide] = useState("right");
	const [popWidth, setPopWidth] = useState("420px");
	const buttonRef = useRef(null);
	const popupRef = useRef(null);

	function handleOpen(set) {
		repositionPopup();
		if (typeof set === "boolean" || set instanceof Boolean) {
			setIsOpen(set);
		} else {
			setIsOpen(!isOpen);
		}
	}

	function repositionPopup() {
		const buttonRect = buttonRef.current.getBoundingClientRect();
		const popupRect = popupRef.current.getBoundingClientRect();
		const winWidth = window.innerWidth;

		// Determine left or above based on if there is enough room to the right
		if (buttonRect.left + buttonRect.width + 12 + 420 < winWidth) {
			setLeftOffset(buttonRect.width + 12);
			setTopOffset(popupRect.height / -2 + 12);
			setPopWidth("420px");
			setCarrotSide("right");
		} else {
			setLeftOffset(buttonRect.left * -1 + 12);
			setTopOffset(popupRect.height * -1 - 12);
			setPopWidth("calc(100vw - 38px)");
			setCarrotSide("top");
		}
	}

	useEffect(() => {
		repositionPopup();
		const resizer = window.addEventListener("resize", repositionPopup);
		return () => {
			window.removeEventListener("resize", resizer);
		};
	});

	return (
		<span className={styles.numbers}>
			<button onClick={handleOpen} ref={buttonRef}>
				{Object.keys(children)
					.map((i) => {
						return parseInt(i) + 1;
					})
					.join(", ")}
			</button>
			<span
				className={hasNames(
					styles.corner,
					isOpen ? "visible" : "",
					carrotSide
				)}></span>
			<ol
				className={hasNames(styles.disclosures, isOpen ? "open" : "closed")}
				ref={popupRef}
				style={{
					left: leftOffset + "px",
					top: topOffset + "px",
					width: popWidth,
				}}>
				{children.map((child, i) => {
					return <li key={`static-child-${i}`}>{child}</li>;
				})}
			</ol>
		</span>
	);
};

export default Disclosures;
